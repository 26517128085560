import get from 'lodash/get'
// import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import { reactive, ref, onMounted } from '@vue/composition-api'

import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'

export default (ctx) => {
  const formData = reactive({
    id: undefined,
    lang: 'th',
    uuid: '',
    name: '',
    project_house_sell_gallery_links: '',
    house_sell_gallery_files: [],
    updated_at: null,
    created_by: null,
    updated_by: null,
  })

  const prepareFormData = (result) => {
    console.log('result: ', result)
    formData.id = result.id
    formData.name = result.name
    formData.lang = result.lang
    formData.updated_at = result.updated_at
    formData.created_by = result.created_by
    formData.updated_by = result.updated_by
    formData.folder_name = result.folder_name
    formData.project_house_sell_gallery_links = get(result, 'project_house_sell_gallery_links.0.project_uuid')

    const files = get(result, 'house_sell_gallery_files', [])
    formData.house_sell_gallery_files = orderBy(files, ['image_index'], ['asc'])
  }

  const btnSubmitLabel = ref('Submit')

  const fetchByRouteId = async () => {
    const uuid = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['Gallery']
    if (uuid) {
      btnSubmitLabel.value = 'Update'
      formData.uuid = uuid
      // ctx.root.$vs.loading()
      await ctx.root.$store
        .dispatch('houseSellGallery/fetchOne', uuid)
        .then((result) => {
          prepareFormData(result)
          if (!formData.folder_name) {
            const randomUuid = uuidv4()
            formData.folder_name = randomUuid
          }
          return createFolder(`house-sell-gallery/${formData.folder_name}`, folders)
        })
        // .then(() => ctx.root.$vs.loading.close())
        .catch((error) => {
          console.log(error)
        })
    } else {
      const randomUuid = uuidv4()
      // ctx.root.$vs.loading()
      formData.folder_name = randomUuid
      await createFolder(`house-sell-gallery/${randomUuid}`, folders)
      // ctx.root.$vs.loading.close()
    }
  }

  const getProjectList = async (lang) => {
    if (lang) await ctx.root.$store.dispatch('locale/changeLang', lang)
    await ctx.root.$store.dispatch('project/fetchDataListItems')
    console.log('project: ', ctx.root.$store.state.project.projects)
  }

  
  const serializeFormData = (formData) => {
    let files = get(formData, 'house_sell_gallery_files', [])
    files = files.map((file) => {
      const image_uuid = !file.image_uuid ? uuidv4() : file.image_uuid
      return {
        ...file,
        image_uuid
      }
    })

    const activeUser = ctx.root.$store.state.AppActiveUser
    const email = get(activeUser, 'email', '')
    const createdBy = formData.created_by
    const updatedBy = email

    return {
      ...formData,
      created_by: createdBy,
      updated_by: updatedBy,
      house_sell_gallery_files: files,
      project_house_sell_gallery_links: [{ project_uuid: formData.project_house_sell_gallery_links }]
    }
  }

  onMounted(async () => {
    await ctx.root.$vs.loading()
    await fetchByRouteId()
    await getProjectList()
    await ctx.root.$vs.loading.close()
  })

  return {
    formData,
    prepareFormData,
    fetchByRouteId,
    serializeFormData,
    btnSubmitLabel,
    getProjectList
  }
}
