<template>
  <div>
    <ValidationObserver v-slot="context">
      <vx-card class="mb-base">
        <form @submit.prevent="submit(context)">
          <FormLayout>
            <FormLabel>
              <span>เลือกโครงการ *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="โครงการ" 
                rules="required">
                <vs-select
                  v-model="formData.project_house_sell_gallery_links"
                  name="project_house_sell_gallery_links"
                  icon="arrow_downward"
                  autocomplete
                >
                  <vs-select-item
                    v-for="(item, index) in projectList"
                    :value="item.uuid"
                    :text="item.title"
                    :key="index"
                  />
                </vs-select>
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>ระบุชื่อ Folder *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="ชื่อ Folder"
                rules="required"
              >
                <vs-input
                  v-model="formData.name"
                  class="w-full"
                  name="name"
                  placeholder="ระบุชื่อ Folder"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <div class="w-full px-10 mb-3 border-t border-danger">
              ภาพ Gallery (ขนาดรูป 1920 x 1080 px, type : .JPG , .PNG , .GIF )
            </div>
            <div class="w-full px-10">
              <GalleryForm
                :folder="`${baseFolder}/house-sell-gallery/${formData.folder_name}/Gallery`"
                v-model="formData.house_sell_gallery_files"
                has-alt-text
                has-detail
                selection-name="house_sell_gallery_files"
              />
            </div>
          </FormLayout>
        </form>
      </vx-card>
      <vx-card>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดโดย</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formData.updated_by}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดวันที่</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel />
          <div class="flex items-center vx-col w-full md:w-6/12">
            <vs-button 
              class="mr-2" 
              @click="submit(context)">{{ btnSubmitLabel }}</vs-button>
          </div>
        </FormLayout>
      </vx-card>
    </ValidationObserver>
  </div>
</template>
<script>
import { computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import useCrud from '@/use/useCrud'
import GalleryForm from '@/components/GalleryForm'
import useMasterGalleryHouseSell from '@/use/useMasterGalleryHouseSell'
import env from '@/env'

export default {
  name: 'MasterGalleryHomesellForm',
  components: {
    GalleryForm,
    ValidationObserver,
  },
  setup(props, ctx) {
    const crudForm = useCrud(ctx, 'houseSellGallery')
    crudForm.routePrefix.value = 'property-plan-master-gallery-homesell'

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)
    const { formData, serializeFormData, btnSubmitLabel } = useMasterGalleryHouseSell(ctx)

    const projectList = computed(() => ctx.root.$store.state.project.projects)

    const submit = (ctx) => {
      crudForm.addItem(ctx, serializeFormData(formData))
    }

    return {
      ...crudForm,
      submit,
      formData,
      projectList,
      baseFolder,
      btnSubmitLabel
    }
  },
}
</script>
